// Use state
import { useState, useEffect } from "react";
// Images
import PastoralniCentar2 from "../../../Images/pastoralni centar/centar2.jpg";
import PastoralniCentar3 from "../../..//Images/pastoralni centar/centar3.jpg";
import PastoralniCentar4 from "../../../Images/pastoralni centar/centar4.jpg";
import PastoralniCentar5 from "../../..//Images/pastoralni centar/centar5.jpg";
import PastoralniCentar6 from "../../../Images/pastoralni centar/centar6.jpg";
import Tlocrt1 from "../../../Images/pastoralni centar/tlocrt1.jpg";
import Tlocrt2 from "../../../Images/pastoralni centar/tlocrt2.jpg";
import Tlocrt3 from "../../../Images/pastoralni centar/tlocrt3.jpg";
// Hash Link
import { HashLink } from "react-router-hash-link";

export default function PastoralniCentar() {
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };
  useEffect(() => {
    document.title = "Don Bosco | Pastoralni centar";
  }, []);
  return (
    <>
      {/* *****MAIN***** */}
      <main>
        <div className="main-img" id="pastoralni-centar">
          <img src={PastoralniCentar3} alt="Župa Don Bosco" />
          <div className="main-dusa">
            <h2>"Neka te uvijek vodi razum, a ne strasti."</h2>
            <p>
              <i className="fi fi-tr-horizontal-rule"></i> Don Bosco
            </p>
          </div>
        </div>
      </main>
      {/* *****PASTORALNI SLIKA 1***** */}
      <section className="section">
        <h1>NOVI PASTORALNI CENTAR</h1>
        <div className="pastoralni-box">
          <img
            src={PastoralniCentar4}
            alt="Pastoralni centar"
            onClick={() => openImage(PastoralniCentar4)}
          />
          <p>
            Draga braćo i sestre! Radosna vas srca obavještavamo da je u
            Podsusedu pokrenut projekt izgradnje novog pastoralnog centra "Sv.
            Ivan Bosco". Župljani župe Sv. Ivan Bosco već 20 godina sanjaju
            ovakav projekt i sada je konačno stiglo vrijeme da se taj san
            ostvari.
          </p>
        </div>
      </section>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
      <PastoralniVideoDonacije />
      <PastoralniCentarSlike />
    </>
  );
}

/* *****PASTORALNI VIDEO (Donacije)****** */

function PastoralniVideoDonacije() {
  return (
    <>
      <section
        id="section-pastoralni"
        class="section"
        style={{ marginBottom: "25px" }}
      >
        <h1>Pastoralni centar</h1>
        <div class="pastoralni-video">
          <iframe
            src="https://www.youtube.com/embed/TFdxcKIkrjI?si=NUJA_aP80g1U0Zny"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            id="video"
          ></iframe>
          <div style={{ marginBottom: "25px" }}>
            <p class="pastoralni-text">
              Centar predstavlja nedostajuće prostore Oratorija salezijanske
              župe u Podsused i zaokružuje prostor pristupnog trga ispred
              samostana i crkve sv.Ivan Bosco.
            </p>
          </div>
          <HashLink smooth to="/donacije/#section-donacije" id="donation">
            Donacije&rarr;
          </HashLink>
        </div>
      </section>
    </>
  );
}

/* *****PASTORALNI CENTAR SLIKE***** */
function PastoralniCentarSlike() {
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };
  return (
    <>
      <section className="section">
        <div className="pastoralni-box">
          <img
            src={PastoralniCentar2}
            alt="Pastoralni centar"
            onClick={() => openImage(PastoralniCentar4)}
          />
          <p className="pastoralni-text">
            Planira se na mjestu postojećeg vanjskog igrališta i garaža
            samostana te je smješten u visinskoj razlici postojećeg terena s
            pristupom novim prostorima sa razine pristupnog trga i
            novoplaniranog vanjskog igrališta s tribinama na krovu, odnosno na
            razini današnjeg igrališta i bočnog pristupa u samostan.
          </p>
        </div>

        <div className="light-border"></div>

        <div className="pastoralni-box">
          <img
            src={PastoralniCentar5}
            alt="Pastoralni centar"
            onClick={() => openImage(PastoralniCentar5)}
          />
          <p>
            Središnje mjesto prostora čini velika dvorana Oratorija s vanjskim
            prostorom trijema koji je produžetak i prijelaz unutarnjeg na
            vanjske prostore.
          </p>
        </div>

        <div className="light-border"></div>

        <div className="pastoralni-box">
          <img
            src={PastoralniCentar6}
            alt="Pastoralni centar"
            onClick={() => openImage(PastoralniCentar6)}
          />
          <p>
            Novi pastoralni centar predstavlja dovršetak prostora župe (zajedno
            s nedostajućim servisnim prostorima) i urbanističko zaokruživanje
            vanjskog prostora ispred crkve.
          </p>
        </div>

        <div className="light-border"></div>

        <div className="pastoralni-box">
          <img
            src={Tlocrt1}
            alt="Pastoralni centar"
            onClick={() => openImage(Tlocrt1)}
          />
          <p>
            Vizualno se uklapa u visinsku razliku postojeće podsusedske padine
            te vanjskom formom uokviruje sve novoplanirane sadržaje.
          </p>
          <p>
            Prvi je korak bilo odobrenje Provincije i izrada ovog idejnog
            rješenja.
          </p>
        </div>

        <div className="light-border"></div>

        <div className="pastoralni-box">
          <img
            src={Tlocrt2}
            alt="Pastoralni centar"
            onClick={() => openImage(Tlocrt2)}
          />
          <p>
            Nakon odobrenja Gradskog ureda za planiranje i izgradnju te prikuplj
            enih svih posebnih uvjeta, izrađuje se glavni projekt s kojim se ide
            u ishođenje građevinske dozvole.
          </p>
        </div>

        <div className="light-border"></div>

        <div className="pastoralni-box">
          <img
            src={Tlocrt3}
            alt="Pastoralni centar"
            onClick={() => openImage(Tlocrt3)}
          />
        </div>
        {/* *****SHOW IMAGES ON CLICK***** */}
        {showImage && (
          <div id="overlay" onClick={closeImage}>
            <div id="popup">
              <span id="close" onClick={closeImage}>
                &times;
              </span>
              <img
                id="popupImage"
                src={showImage}
                style={{ width: "100%", height: "100%" }}
                alt="Show"
              />
            </div>
          </div>
        )}
      </section>
    </>
  );
}
