import { HashLink } from "react-router-hash-link";
import Animatori1 from "../../../../Images/animatori/Animatori Podsused.jpg";
import { useNavigate } from "react-router-dom";

export default function Animatori() {
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };
  return (
    <>
      {/* *****MAIN***** */}
      <main>
        <div className="main-img">
          <img src={Animatori1} alt="Župa Don Bosco" />
          <div className="main-dusa">
            <h2>"Daj mi duše, drugo uzmi"</h2>
            <p>
              <i className="fi fi-tr-horizontal-rule"></i> Don Bosco
            </p>
          </div>
        </div>
      </main>
      {/* *****ANIMATORI***** */}
      <section className="section">
        <h1>Postani animator</h1>
        <div className="animatori-grid">
          <img src={Animatori1} alt="Animatori Podsused" />
          <p>
            Animatori to smo mi. Skupina mladih iz Župe Sv. Ivana Boska iz
            Podsuseda. Ali što su to animatori? Koliko godina imaju animatori?
            Što oni to rade? Zašto to rade? I ono konačno kako postati animator?
          </p>
          <p>
            E pa animatori su mladi, srednjoškolci i stariji koji su odlučili
            dio svoga slobodnog vremena posvetiti radu s mladima u župi.
            Zabavljanje i zafrkancija sa djecom uglavnom od 3 do 8 razreda
            osnovne škole samo je mali dio onoga što spada u opis svakog
            animatora jer naš je cilj kroz igru i smijeh približiti na zabavan
            način Isusa, Mariju i sve ostale kršćanske uzore mladima.
          </p>
          <p>
            Kada prvi put nekome kažeš da si animator najčešće će te samo
            blijedo pogledati ili ti se čak nasmijati. No nije to tako smiješno
            jer većina ljudi kada im bolje objasniš tko smo i što smo shvate da
            je to vrlo zanimljivo.
          </p>
          <p>
            Pa sada preciznije. Animatori iz Podsuseda spadaju u Salezijanske
            animatore koji se nalaze širom cijelog svijeta gdje god se nalaze i
            Salezijanci. U Zagrebu salezijanski animatori su u župama u Rudešu
            na Jurunu i na Knežiji. Salezijanski animatori za razliku od
            biskupijskih animatora još posebnu pozornost skreću na salezijansku
            karizmu koju smo naslijedili od Sv Ivana Boska utemeljitelja
            salezijanske družine. On je poseban naglasak stavio na rad s mladima
            i razvio specifični odgojno preventivni sustav.
          </p>
          <p>
            Sv Ivan Bosko, Madre Mazarello , Dominik Savio , Laura Vicunna , sve
            su to uzori koji se često spominju u salezijanskim odgojnim metdama.
          </p>
        </div>
        <h4>Želiš postati animator?</h4>
        <HashLink
          smooth
          to="/prijava"
          id="prijavi-se-btn"
          onClick={() => handleClick("/")}
        >
          Prijavi se &rarr;
        </HashLink>
      </section>
    </>
  );
}
