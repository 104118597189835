// Styles
import "./Styles/App.scss";

// Images
import MainBackground from "./Images/pastoralni centar/centar6.jpg";

// Components
import {
  PastoralniVideo,
  Dogadanja,
  ObavijestiText,
  SvetaMisa,
  Blagoslov,
  UkljuciSe,
} from "./Components";

// Links
import { HashLink } from "react-router-hash-link";

// Use Effect
import { useEffect } from "react";

export default function App() {
  useEffect(() => {
    document.title = "Don Bosco | Naslovnica";
  }, []);
  return (
    <div>
      {/* *****MAIN***** */}
      <main>
        <div class="main-img">
          <img src={MainBackground} alt="Župa Don Bosco" />
          <div class="main-dusa">
            <h2>"Daj mi duše, drugo uzmi"</h2>
            <p>
              <i class="fi fi-tr-horizontal-rule"></i> Don Bosco
            </p>
          </div>
        </div>
      </main>
      {/* *****DONACIJE BUTTON***** */}
      <HashLink smooth to="/donacije/#section-donacije" class="donacija-btn">
        Donacije&rarr;
      </HashLink>
      {/* *****SVETA MISA***** */}
      <SvetaMisa />
      {/* *****PASTORALNI CENTAR***** */}
      <PastoralniVideo />
      {/* *****OBAVIJESTI***** */}
      <ObavijestiText />
      {/* -----BORDER LINE----- */}
      <div class="light-border"></div>
      {/* *****DOGAĐANJA***** */}
      <Dogadanja />
      {/* -----BORDER LINE----- */}
      <div class="light-border"></div>
      {/* *****BLAGOSLOV OBITELJI***** */}
      <Blagoslov />
      {/* -----BORDER LINE----- */}
      <div class="light-border"></div>
      {/* *****UKLJUCI SE***** */}
      <UkljuciSe />
    </div>
  );
}
