import { useEffect } from "react";

// Components
import ObavijestiText from "./Naslovnica/ObavijestiText";
// Images
import PastoralniCentar2 from "../../Images/pastoralni centar/centar2.jpg";

export default function Obavijesti() {
  useEffect(() => {
    document.title = "Don Bosco | Obavijesti";
  }, []);
  return (
    <>
      <main>
        <div className="main-img">
          <img src={PastoralniCentar2} alt="Župa Don Bosco" />
          <div className="main-dusa">
            <h2>"Daj mi duše, drugo uzmi"</h2>
            <p>
              <i class="fi fi-tr-horizontal-rule"></i> Don Bosco
            </p>
          </div>
        </div>
      </main>
      {/* *****OBAVIJESTI***** */}
      <ObavijestiText />
      {/* -----BORDER LINE----- */}
      <div className="light-border"></div>
      <MisneNakane />
    </>
  );
}

function MisneNakane() {
  return (
    <>
      <section className="section">
        <h1>Misne nakane</h1>
        <h3>24. 12. – 30. 12. 2023.</h3>
        <div className="nakane-table">
          {/* -----PONEDJELJAK----- */}
          <div className="nakane-rows">
            <div className="nakane-day">
              <h4>Ponedjeljak</h4>
              <span>18. 12. 2023.</span>
            </div>
            <div>
              <div className="nakane-grid">
                <h6>06:00</h6>
                <div className="nakane-names">
                  <p>&#10013; Manda Palijan (greg)</p>
                  <p>&#10013; Vukoje, Mara i Marko</p>
                </div>
              </div>
              <div className="nakane-grid">
                <h6>18:30</h6>
                <div className="nakane-names">
                  <p>Antun Cerinski i pok iz ob. Cerinski</p>
                  <p>Stjepan i Tomica Rogina i Sandra</p>
                  <p>Po nakani na čast Majke Božje</p>
                </div>
              </div>
            </div>
          </div>
          {/* -----UTORAK----- */}
          <div className="nakane-rows">
            <div className="nakane-day">
              <h4>Utorak</h4>
              <span>19. 12. 2023.</span>
            </div>
            <div>
              <div className="nakane-grid">
                <h6>06:00</h6>
                <div className="nakane-names">
                  <p>Manda Palijan (greg)</p>
                  <p>Zlatko Plevko</p>
                </div>
              </div>
              <div className="nakane-grid">
                <h6>18:30</h6>
                <div className="nakane-names">
                  <p>Ana Semenov</p>
                  <p>Vilma, Ivan, Drago i Mirjam Škreblin</p>
                  <p>Zlatko Maurović</p>
                  <p>Na čast Majci Božjoj</p>
                </div>
              </div>
            </div>
          </div>
          {/* -----SRIJEDA----- */}
          <div className="nakane-rows">
            <div className="nakane-day">
              <h4>Srijeda</h4>
              <span>20. 12. 2023.</span>
            </div>
            <div>
              <div className="nakane-grid">
                <h6>06:00</h6>
                <div className="nakane-names">
                  <p>Manda Palijan (greg)</p>
                </div>
              </div>
              <div className="nakane-grid">
                <h6>18:30</h6>
                <div className="nakane-names">
                  <p>
                    Ilija i Marija Magovac te Zoran Zadravec; Ljubica i Anton
                    Trtanj, te Gordan Čačić
                  </p>
                  <p>Zvonimir i Ivan Krpan</p>
                  <p>Stanko Poščić</p>
                  <p>Po nakani za 0bitelj</p>
                </div>
              </div>
            </div>
          </div>
          {/* -----Četvrtak----- */}
          <div className="nakane-rows">
            <div className="nakane-day">
              <h4>Četvrtak</h4>
              <span>21. 12. 2023.</span>
            </div>
            <div>
              <div className="nakane-grid">
                <h6>06:00</h6>
                <div className="nakane-names">
                  <p>Manda Palijan (greg)</p>
                  <p>Antun i Danica Božičević</p>
                </div>
              </div>
              <div className="nakane-grid">
                <h6>18:30</h6>
                <div className="nakane-names">
                  <p>Dragutin Hutinec, stariji i mlađi</p>
                  <p>Marija i Augustin Veršić; Milka i Stjepan Brković</p>
                  <p>Za sretan ishod operacije</p>
                </div>
              </div>
            </div>
          </div>
          {/* -----Petak----- */}
          <div className="nakane-rows">
            <div className="nakane-day">
              <h4>Petak</h4>
              <span>22. 12. 2023.</span>
            </div>
            <div>
              <div className="nakane-grid">
                <h6>06:00</h6>
                <div className="nakane-names">
                  <p>Manda Palijan (greg)</p>
                </div>
              </div>
              <div className="nakane-grid">
                <h6>18:30</h6>
                <div className="nakane-names">
                  <p>Ante i Mery Vlaić</p>
                  <p>Milka i Stjepan</p>
                  <p>Za Obitelj</p>
                  <p>Marija Kesak</p>
                </div>
              </div>
            </div>
          </div>
          {/* -----SUBOTA----- */}
          <div className="nakane-rows">
            <div className="nakane-day">
              <h4>Subota</h4>
              <span>23. 12. 2023.</span>
            </div>
            <div>
              <div className="nakane-grid">
                <h6>06:00</h6>
                <div className="nakane-names">
                  <p>Manda Palijan greg.</p>
                  <p>Ruža i Petar Mandir</p>
                </div>
              </div>
              <div className="nakane-grid">
                <h6>18:30</h6>
                <div className="nakane-names">
                  <p>Stjepan Bokor</p>
                  <p>Pavlinka Jambrišak</p>
                  <p>Pok iz ob. Stjepana i Ane Hršak</p>
                </div>
              </div>
            </div>
          </div>
          {/*  -----NEDJELJA----- */}
          <div className="nakane-rows">
            <div className="nakane-day" style={{ borderBottom: "none" }}>
              <h4>Nedjelja</h4>
              <span>24. 12. 2023.</span>
            </div>
            <div>
              <div className="nakane-grid">
                <h6>07:00</h6>
                <div className="nakane-names">
                  <p>Manda Palijan</p>
                </div>
              </div>
              <div className="nakane-grid">
                <h6>09:00</h6>
                <div className="nakane-names">
                  <p>Za Narod</p>
                </div>
              </div>
              <div className="nakane-grid">
                <h6>10:30</h6>
                <div className="nakane-names">
                  <p>Za Narod</p>
                </div>
              </div>
              <div className="nakane-grid">
                <h6>20:00</h6>
                <div className="nakane-names">
                  <p>Pok iz ob. Bišćan i Knezić</p>
                </div>
              </div>
              <div className="nakane-grid">
                <h6>22:00</h6>
                <div className="nakane-names">
                  <p>Mario Kečkeš i Stipe Čulo</p>
                </div>
              </div>
              <div className="nakane-grid">
                <h6 style={{ borderBottom: "none" }}>24:00</h6>
                <div className="nakane-names" style={{ borderBottom: "none" }}>
                  <p>Eva i Grga Marković</p>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- KRAJ --> */}
        </div>
      </section>
    </>
  );
}
