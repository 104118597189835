import PastoralniCentar from "../../../../Images/pastoralni centar/centar4.jpg";

export default function Caritas() {
  return (
    <>
      {/* *****MAIN***** */}
      <main>
        <div className="main-img">
          <img src={PastoralniCentar} alt="Župa Don Bosco" />
          <div className="main-dusa">
            <h2>"Daj mi duše, drugo uzmi"</h2>
            <p>
              <i className="fi fi-tr-horizontal-rule"></i> Don Bosco
            </p>
          </div>
        </div>
      </main>
      {/* *****ANIMATORI***** */}
      <section className="section">
        <h1>Cartias</h1>
        <div className="animatori-grid">
          {/* <img src={Animatori1} alt="Animatori Podsused" /> */}
          <p></p>
        </div>
      </section>
    </>
  );
}
