import { useEffect } from "react";

// Images
import PastoralniCentar from "../../Images/pastoralni centar/centar1.jpg";
import Logo from "../../Images/logo/DBKucaB.png";

export default function Donacije() {
  useEffect(() => {
    document.title = "Don Bosco | Donacije";
  }, []);
  return (
    <>
      <main>
        <div className="main-img">
          <img src={PastoralniCentar} alt="Župa Don Bosco" />
          <div className="main-dusa">
            <h2>"Daj mi duše, drugo uzmi"</h2>
            <p>
              <i className="fi fi-tr-horizontal-rule"></i> Don Bosco
            </p>
          </div>
        </div>
      </main>
      {/* <!-- -----SECTION DONACIJE----- --> */}
      <section id="section-donacije" className="section">
        <h1>Donacije</h1>
        <p>
          U svrhu izgradnje centra otvoren je poseban bankovni račun namjenjen
          isključivo tome. Vašim doprinosom uvelike ćete pomoći izgradnji.
        </p>
        <div className="bank-amout">
          <div className="amout-line">113 271€</div>
          1,000 000€
        </div>
        {/*  <!--  --> */}
        <div className="light-border"></div>
        {/*    <!--  --> */}
        <div className="donacije-flex">
          <div className="donacije-box">
            <img src={Logo} alt="Don Bosco Logo" />
            <h4>Donacije za Pastoralni Centar</h4>
            <p>IBAN: HR 1323600001502135540</p>
            <p>SWIFT: ZABAHR2X</p>
          </div>

          <div className="donacije-box">
            <img src={Logo} alt="Don Bosco Logo" />
            <h4>Donacije za salezijance</h4>
            <p>IBAN: HR8923600001101576679</p>
            <p>SWIFT: ZABAHR2X</p>
          </div>
        </div>
        {/*       <!--  --> */}
        <div className="light-border"></div>
        {/*     <!--  --> */}
        <p>
          Dragi župljani, dragi dobročinitelji, u ime Župnog ekonomskog i
          pastoralnog vijeća želimo Vam se zahvaliti na svim donacijama kojima
          nesebično darivate za potrebe izgradnje novog pastoralnog centra Sv.
          Ivan Bosco. Preporučamo se u vašim molitvama...
        </p>
        {/*  <!--  --> */}
        <div className="light-border"></div>
        {/* <!--  --> */}
        <p>
          Stanje sredstava na donatorskom bankovnom računu s danom 29.12.2022.
          iznosi 746.062,74 kn i 3.240,02 EUR, u što je uključeno 100.000,00 kn
          od Salezijanske provincije.
        </p>
        <div className="light-border"></div>
        {/* <!--  --> */}
        <h5>Hvala! Vaši Salezijanci</h5>
      </section>
    </>
  );
}
