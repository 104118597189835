import { HashLink } from "react-router-hash-link";
import Knjiga from "../../../../Images/čitači/knjiga.jpg";
import { useNavigate } from "react-router-dom";

export default function Citaci() {
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };
  return (
    <>
      {/* *****MAIN***** */}
      <main>
        <div className="main-img">
          <img src={Knjiga} alt="Župa Don Bosco" />
          <div className="main-dusa">
            <h2>"Daj mi duše, drugo uzmi"</h2>
            <p>
              <i className="fi fi-tr-horizontal-rule"></i> Don Bosco
            </p>
          </div>
        </div>
      </main>
      {/* *****ČITAČI***** */}
      <section className="section">
        <h1>Postani čitač</h1>
        <p></p>
        <h4>Želiš čitati</h4>
        <HashLink
          smooth
          to="/prijava"
          id="prijavi-se-btn"
          onClick={() => handleClick("/")}
        >
          Prijavi se &rarr;
        </HashLink>
      </section>
    </>
  );
}
