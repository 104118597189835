// Images
import { useState } from "react";
import LjetniOratorij from "../../../Images/oratorij/Ljetni oratorij 2023 slika 1.jpg";
import { HashLink } from "react-router-hash-link";

export default function Dogadanja() {
  return (
    <section id="section-novosti" className="section">
      <h1>Događanja</h1>
      <div className="novosti-grid">
        <Dogadanja1 />
        <div className="novosti-border"></div>
        <Dogadanja1 />
        <div className="novosti-border"></div>
        <Dogadanja1 />
      </div>
    </section>
  );
}

/* *****LJETNI ORATORIJ***** */
function Dogadanja1() {
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };
  return (
    <>
      <div className="oratorij-box">
        <h3>
          LJETNI ORATORIJ<span>2023</span>
        </h3>
        <img
          src={LjetniOratorij}
          alt="Ljetni Oratorij"
          onClick={() => openImage(LjetniOratorij)}
        />
        <div className="oratorij-text">
          <p>
            Dragi naši, s radošću vas obavještavamo da su prijave za LJETNI
            ORATORIJ 2023. OTVORENE!!!
          </p>
          <HashLink className="dogadaji-btn" smooth to="/">
            Više Informacija &rarr;
          </HashLink>
        </div>
      </div>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
    </>
  );
}

/*    <div className="obavijesti-dots">
        <div className="dot active-dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div> */
