import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation();
  const hideFooter = ["/hvala"];
  let year = new Date().getFullYear();
  if (hideFooter.includes(location.pathname)) {
    return <></>;
  }
  return (
    <footer>
      <div className="footer-top">
        <div className="footer-links">
          <h3>Zapratite nas</h3>
          <Link to="https://www.facebook.com/oratorijpodsused/" target="_blank">
            <i className="fi fi-brands-facebook"></i>
          </Link>
          <Link
            to="https://www.instagram.com/oratorij_adc/?hl=hr"
            target="_blank"
          >
            <i className="fi fi-brands-instagram"></i>
          </Link>
          <Link
            to="https://www.youtube.com/@oratorijalexandrinadacosta4747"
            target="_blank"
          >
            <i className="fi fi-brands-youtube"></i>
          </Link>
        </div>

        <div className="footer-border"></div>

        <div className="footer-contact">
          <h3>Kontak</h3>
          <a href="mailto:zupa.podsused@zg-nadbiskupija.hr">
            <i className="fi fi-rr-envelope"></i>
            zupa.podsused@zg-nadbiskupija.hr
          </a>
          <a href="tel:(01) 3492 033">
            <i className="fi fi-rr-phone-call"></i> (01) 3492 033
          </a>

          <Link to="https://maps.app.goo.gl/1B3btrBDwZpyzPMc7" target="_blank">
            Vinobreška ul. 2, 10090, Zagreb
          </Link>
        </div>

        <div className="footer-border"></div>

        <div className="footer-links">
          <h3>Ostalo</h3>

          <HashLink smooth to="/donacije/#section-donacije">
            Donacija &rarr;
          </HashLink>
          <HashLink smooth to="/zupa/#povijest">
            Povijest Župe &rarr;
          </HashLink>
          <HashLink smooth to="salezijanci/#salezijanci">
            Salezijanci &rarr;
          </HashLink>
          <HashLink smooth to="salezijanci/#DonBosco">
            Don Bosco &rarr;
          </HashLink>
        </div>
      </div>

      <div className="footer-border"></div>

      <p>
        &copy; <h4>{year}</h4> Župa Sveti Ivan Don Bosco
      </p>
    </footer>
  );
}
