import Animatori from "../../../Images/pastoralni centar/centar4.jpg";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";

export default function UkljuciSe() {
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };
  return (
    <section className="section">
      <h1>Uključi se u župu</h1>
      <h3>Postani aktivni član zajednice</h3>
      <div className="join-us">
        {/*   <!-- ANIMATORI --> */}
        <HashLink
          smooth
          to="/animatori"
          className="join-us-box"
          onClick={() => handleClick("/")}
        >
          <img className="join-us-img" src={Animatori} alt="Animatori" />
          <h4>Animatori</h4>
        </HashLink>
        {/*    <!-- CARITAS --> */}
        <HashLink
          smooth
          to="/caritas"
          className="join-us-box"
          onClick={() => handleClick("/")}
        >
          <img className="join-us-img" src={Animatori} alt="Caritas" />
          <h4>Caritas</h4>
        </HashLink>
        {/*   <!-- ČITAČI --> */}
        <HashLink
          smooth
          to="/čitači"
          className="join-us-box"
          onClick={() => handleClick("/")}
        >
          <img className="join-us-img" src={Animatori} alt="Čitači" />
          <h4>Čitači</h4>
        </HashLink>

        {/*    <!-- MINISTRANTI --> */}
        <a href="/" className="join-us-box">
          <img className="join-us-img" src={Animatori} alt="Ministranti" />
          <h4>Ministranti</h4>
        </a>
        {/*    <!-- PJEVAČI --> */}
        <a href="/" className="join-us-box">
          <img className="join-us-img" src={Animatori} alt="Pjevači" />
          <h4>Pjevači</h4>
        </a>
        {/*   <!-- OSTALO --> */}
        <a href="/" className="join-us-box">
          <img className="join-us-img" src={Animatori} alt="Ostalo" />
          <h4>Ostalo</h4>
        </a>
      </div>
    </section>
  );
}
