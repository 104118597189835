import PastoralniCentar from "../../../../Images/pastoralni centar/centar3.jpg";

export default function PrijaviSe() {
  return (
    <>
      <main>
        <div className="main-img">
          <img src={PastoralniCentar} alt="Župa Don Bosco" />
          <div className="main-dusa">
            <h2>"Daj mi duše, drugo uzmi"</h2>
            <p>
              <i className="fi fi-tr-horizontal-rule"></i> Don Bosco
            </p>
          </div>
        </div>
      </main>
      <section class="section">
        <h1>Želiš postati član naše zajednice?</h1>
        <div>
          <h3>Ispuniti prijavu</h3>
        </div>
        <form
          action="https://api.web3forms.com/submit"
          method="POST"
          class="contact-form"
          id="form"
        >
          {/*  <!-- Key --> */}
          <input
            type="hidden"
            name="access_key"
            value="f1e0e1f5-8e00-4401-ab44-2ee82c6909f3"
          />
          {/*   <!-- Redirect --> */}
          <input
            type="hidden"
            name="redirect"
            value="https://donbosco-podsused.pages.dev/hvala"
          />
          {/*  <!-- From name --> */}
          <input type="hidden" name="from_name" value="Uključi se u Župu" />
          {/* <!-- Subject name --> */}
          <input type="hidden" name="subject" value="Nova prijavnica" />
          {/*  <!--  --> */}

          <label for="name">
            Ime i Prezime <span>*</span>
          </label>
          <input
            type="text"
            name="Prezime"
            placeholder="Prezime obitelji"
            required
          />
          <label for="email">
            Email: <span>*</span>
          </label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="primjer@gmail.com"
            required
          />
          <label for="phone">
            Mobitel: <span>*</span>
          </label>
          <input
            type="tel"
            name="Broj mobitela"
            id="phone"
            placeholder="+385 1 234 5678"
            required
          />
          <label htmlFor="">Odaberite:</label>
          <select name="" id="">
            <option disabled>Odaberite</option>
            <option value="Animator">Animator</option>
            <option value="Čitač">Čitač</option>
            <option value="Pjevač">Pjevač</option>
            <option value="Ministrant">Ministrant</option>
          </select>

          <label htmlfor="message">Nešto o tebi...</label>
          <textarea
            name="Poruka"
            id="message"
            placeholder="Vaša poruka..."
          ></textarea>

          {/*  <!-- Honeypot --> */}
          <input
            type="checkbox"
            name="botcheck"
            class="hidden"
            style={{ display: "none" }}
          />
          <input type="submit" value="POŠALJI" id="submit-btn" />
        </form>
      </section>
    </>
  );
}
