export default function Blagoslov() {
  return (
    <section class="section">
      <h1>Prijava za blagoslov obitelji</h1>
      <div>
        <h3>ISPUNITE OBRAZAC</h3>
      </div>
      <form
        action="https://api.web3forms.com/submit"
        method="POST"
        class="contact-form"
        id="form"
      >
        {/*  <!-- Key --> */}
        <input
          type="hidden"
          name="access_key"
          value="f1e0e1f5-8e00-4401-ab44-2ee82c6909f3"
        />
        {/*   <!-- Redirect --> */}
        <input
          type="hidden"
          name="redirect"
          value="https://donbosco-podsused.pages.dev/hvala"
        />
        {/*  <!-- From name --> */}
        <input type="hidden" name="from_name" value="Blagoslov obitelji" />
        {/* <!-- Subject name --> */}
        <input type="hidden" name="subject" value="Nova prijavnica" />
        {/*  <!--  --> */}

        <label for="name">
          Prezime obitelji: <span>*</span>
        </label>
        <input
          type="text"
          name="Prezime"
          placeholder="Prezime obitelji"
          required
        />
        <label for="email">
          Email: <span>*</span>
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="primjer@gmail.com"
          required
        />
        <label for="phone">
          Mobitel: <span>*</span>
        </label>
        <input
          type="tel"
          name="Broj mobitela"
          id="phone"
          placeholder="+385 1 234 5678"
          required
        />

        <label for="street">
          Ulica i broj, kat <span>*</span>
        </label>
        <input
          type="text"
          name="Ulica i broj"
          id="street"
          placeholder="Ulica i broj, kat"
          required
        />

        <label for="message">Pošaljite poruku:</label>
        <textarea
          name="Poruka"
          id="message"
          placeholder="Vaša poruka..."
        ></textarea>

        {/*  <!-- Honeypot --> */}
        <input
          type="checkbox"
          name="botcheck"
          class="hidden"
          style={{ display: "none" }}
        />
        <input type="submit" value="POŠALJI" id="submit-btn" />
      </form>
    </section>
  );
}
