import StaraCrkva from "../../Images/crkva/staracrkva.jpg";
import { useEffect } from "react";

export default function Zupa() {
  useEffect(() => {
    document.title = "Don Bosco | Župa";
  }, []);
  return (
    <>
      <main>
        <div className="main-img">
          <img src={StaraCrkva} alt="Župa Don Bosco" />
          <div class="main-dusa">
            <h2>"Daj mi duše, drugo uzmi"</h2>
            <p>
              <i className="fi fi-tr-horizontal-rule"></i> Don Bosco
            </p>
          </div>
        </div>
        <div id="povijest"></div>
      </main>
      {/* *****ŽUPA BUTTON***** */}
      <div className="zupa-buttons">
        <a href="#povijest">Povijest</a>
        <a href="#dogadaji">Događaji</a>
        <a href="#svecenici">Svećenici</a>
      </div>
      {/*  *****POVIJEST ŽUPE*****  */}
      <PovijestZupe />
      {/* -----BORDER LINE----- */}
      <div className="light-border"></div>
      {/* *****DOGAĐAJI U ŽUPI***** */}
      <DogadajiZupa />
      {/* -----BORDER LINE----- */}
      <div className="light-border"></div>
      {/* *****SVEĆENICI ŽUPE***** */}
      <SveceniciZupe />
    </>
  );
}

/* *****POVIJEST ŽUPE***** */
function PovijestZupe() {
  return (
    <section className="section">
      <h1>Povijest župe</h1>
      <h3>Iz povijesti naše župe i kuće ističemo ove događaje:</h3>

      <div className="povijest-box">
        <p>
          Kad su salezijanci stigli u Podsused započeli su s izgradnjom nove
          crkve u čast velikom apostolu mladeži sv.Ivanu Boscu.Tada nisu mislili
          na župu, nego prvenstveno na otvaranje duhovnog centra:omladinskog
          doma i Oratorija za rad s mladima.
        </p>
        <p>
          Promicatelj gradnje crkve bio je uvaženi don Frane Gulešić, projektant
          zagrebački arhitekt gosp. Zvonko Požgaj, a graditelj gosp. R. Galin.
          Crkva je sagrađena u nekoliko mjeseci kao skladna četvrtasta građevina
          ravnih linija s 20 m dužine i 10 m širine.
        </p>
        <p>
          Uz crkvu je podignut i zvonik.Unutrašnjost crkve je jednobrodna s
          velikim korom. Strop je drven,kazetiran. Na pročelju crkve nalazi se
          lijep reljef Don Bosca međun dječacima.U crkvi se ističe glavni oltar
          od punog bračkog kamena.
        </p>
        <p>
          Takva je i krstionica s lijeve strane svetišta. U crkvi su i dva
          pokrajna oltara s postamentima za kipove Srca Isusova i Marije
          Pomoćnice. Postamenti su izvedeni po nacrtima kiparice prof. Lojzike
          Ulman.
        </p>
        <p>
          Deset obojenih prozora s likovima hrvatskih i drugih svetaca darovi su
          pojedinaca ili katoličkih društava iz prvih godina postojanja crkve i
          župe.Tri obojena prozora također s likovima hrvatskih svetaca i
          blaženika napravljeni su 2004.godine.
        </p>
        <p>
          Tu je i orginalni križni put – mozaik od akademskog kipara gosp.
          Stanka Gašpareca - salezijanca. Promatrajući zauzeto djelo
          salezijanaca i predviđajući razvoj grada, tadašnji nadbiskup dr.
          Alojzije Stepinac odluči osnovati novu župu u Podsusedu i povjeriti je
          njima, što su oni i prihvatili.
        </p>
        <div id="dogadaji"></div>
        <p>
          Župa je ustanovljena i otvorena 6.rujna 1942. a prvim upraviteljem
          župe imenovan je don Franjo Skuhala.
        </p>
      </div>
    </section>
  );
}
/* *****DOGAĐAJI U ŽUPI***** */
function DogadajiZupa() {
  return (
    <section className="section">
      <h1>Događaji</h1>
      <div className="zupa-grid">
        <div className="zupa-box">
          <h5>1939</h5>
          <span>&rarr;</span>
          <p>Sagrađena crkva sv.Ivana Bosca</p>
        </div>
        <div className="zupa-box">
          <h5>1941</h5>
          <span>&rarr;</span>
          <p>
            Postavljeni prvi slikani prozori, nabavljene klupe, obojena crkva
          </p>
        </div>
        <div className="zupa-box">
          <h5>1942</h5>
          <span>&rarr;</span>
          <p>Osnovana je župa sv.Ivana Bosca 6.rujna 1942</p>
        </div>
        <div className="zupa-box">
          <h5>1943</h5>
          <span>&rarr;</span>
          <p>Gradnja jednog dijela kuće</p>
        </div>
        <div className="zupa-box">
          <h5>1948</h5>
          <span>&rarr;</span>
          <p>Postavljeno ulazno stubište iz Vinobreške ulice</p>
        </div>
        <div className="zupa-box">
          <h5>1951</h5>
          <span>&rarr;</span>
          <p>Nabavljene prve orgulje</p>
        </div>
        <div className="zupa-box">
          <h5>1957</h5>
          <span>&rarr;</span>
          <p>Uređen podni dio prezbiterija</p>
        </div>
        <div className="zupa-box">
          <h5>1962</h5>
          <span>&rarr;</span>
          <p>Izrađen križni put u mozaiku (autor:gosp. Stanko Gašparec)</p>
        </div>
        <div className="zupa-box">
          <h5>1963</h5>
          <span>&rarr;</span>
          <p>Postavljen kip Srca Isusova (autor: gosp.N. Orčić)</p>
        </div>
        <div className="zupa-box">
          <h5>1964</h5>
          <span>&rarr;</span>
          <p>
            Izrađen novi mramorni oltar i krstionica (autor:gosp.Stanko
            Gašparec)
          </p>
        </div>
        <div className="zupa-box">
          <h5>1965</h5>
          <span>&rarr;</span>
          <p>
            Izrađena nova oltarna slika Don Boska u keramici (P. Perić i W.
            Mayer) i posvećen glavni oltar (14. 03.).Time je posvećena i sama
            crkva
          </p>
        </div>
        <div className="zupa-box">
          <h5>1974</h5>
          <span>&rarr;</span>
          <p>Postavljeno centralno grijanje</p>
        </div>
        <div className="zupa-box">
          <h5>1977</h5>
          <span>&rarr;</span>
          <p>
            Betoniran pločnik ispred crkve, elektrificirana zvona i ozvučena
            crkva
          </p>
        </div>
        <div className="zupa-box">
          <h5>1984</h5>
          <span>&rarr;</span>
          <p>Obnovljeno krovište crkve</p>
        </div>
        <div className="zupa-box">
          <h5>1985</h5>
          <span>&rarr;</span>
          <p>Postavljene nove orgulje (A. Jenko)</p>
        </div>
        <div className="zupa-box">
          <h5>1992</h5>
          <span>&rarr;</span>
          <p>
            Obnovljena fasada crkve i tornja, obojena crkva iznutra; srušen
            stari privremeni župni ured, uvedena ventilacija crkve i novo
            ozvučenje
          </p>
        </div>
        <div className="zupa-box">
          <h5>2001</h5>
          <span>&rarr;</span>
          <p>
            Zahvaljujući upravi Hrvatske salezijanske provincije obnovljena je
            kuća i u njoj smještena Zajednica za odgoj salezijanskih zvanja
          </p>
        </div>
        <div className="zupa-box">
          <h5>2002</h5>
          <span>&rarr;</span>
          <p>Obnovljen pločnik ispred crkve i kuće i ulazno stubište</p>
        </div>
        <div className="zupa-box">
          <h5>2003</h5>
          <span>&rarr;</span>
          <p>
            Na crkvi je saniran krov. Zvonik crkve je dobio novo čelično
            stubište, crkva je iznutra oličena, izmijenjene su električne
            instalacije, a postavljena su još i nova svjetla i klima uređaj. --
            Popravak Orgulja i Zvona
          </p>
        </div>
        <div className="zupa-box">
          <h5>2005</h5>
          <span>&rarr;</span>
          <p>
            Postavljena zadnja tri vitraja i blagoslovljena 10.svibnja 2005
            stubište
          </p>
        </div>
        <div className="zupa-box">
          <h5>2017</h5>
          <span>&rarr;</span>
          <p>
            Detaljno obnovljena unutrašnjost crkve: instalirana nova
            rasvjeta,novi razglas, novi radijatori, obnovljen kor, obnovljeni
            zidovi novim bojama, uređene klupe, postavljen novi pod te
            obnovljena ulazna vrata.
          </p>
          <div id="svecenici"></div>
        </div>
      </div>
    </section>
  );
}
/* *****SVEĆENICI ŽUPE***** */
function SveceniciZupe() {
  return (
    <section className="section">
      <h1>Svećenici</h1>
      <h3>Župnici u službi župe:</h3>
      <div className="zupa-grid">
        <div className="zupa-box">
          <h5>1942. - 1955.</h5>
          <span>&rarr;</span>
          <p>Franjo Skuhala</p>
        </div>
        <div className="zupa-box">
          <h5>1955. - 1960.</h5>
          <span>&rarr;</span>
          <p>Albin Česlar</p>
        </div>
        <div className="zupa-box">
          <h5>1960. - 1963.</h5>
          <span>&rarr;</span>
          <p>Marin Mandić</p>
        </div>
        <div className="zupa-box">
          <h5>1963. - 1969.</h5>
          <span>&rarr;</span>
          <p>Tadija Dodić</p>
        </div>
        <div className="zupa-box">
          <h5>1969. - 1977.</h5>
          <span>&rarr;</span>
          <p>Augustin Ljubić</p>
        </div>
        <div className="zupa-box">
          <h5>1977. - 1985.</h5>
          <span>&rarr;</span>
          <p>Fabijan Košćak</p>
        </div>
        <div className="zupa-box">
          <h5>1985. - 1989.</h5>
          <span>&rarr;</span>
          <p>Ivan Vargec</p>
        </div>
        <div className="zupa-box">
          <h5>1989. - 1996.</h5>
          <span>&rarr;</span>
          <p>Alojz Jurak</p>
        </div>
        <div className="zupa-box">
          <h5>1996. - 2001.</h5>
          <span>&rarr;</span>
          <p>Žarko Akrap</p>
        </div>
        <div className="zupa-box">
          <h5>2001. - 2002.</h5>
          <span>&rarr;</span>
          <p>Mato Križić</p>
        </div>
        <div className="zupa-box">
          <h5>2002. - 2010.</h5>
          <span>&rarr;</span>
          <p>Tunjo Blažević</p>
        </div>
        <div className="zupa-box">
          <h5>2010. - 2015.</h5>
          <span>&rarr;</span>
          <p>Nedjeljko Nedić</p>
        </div>
        <div className="zupa-box">
          <h5>2015. - 2016.</h5>
          <span>&rarr;</span>
          <p>Stjepan Matijević</p>
        </div>
        <div className="zupa-box">
          <h5>2016. - 2021.</h5>
          <span>&rarr;</span>
          <p>Rudolf Belko</p>
        </div>
        <div className="zupa-box">
          <h5>2021. - 2023.</h5>
          <span>&rarr;</span>
          <p>Anto Stojić</p>
        </div>
        <div className="zupa-box">
          <h5>2023. - Danas</h5>
          <span>&rarr;</span>
          <p>Tunjo Blažević</p>
        </div>
        <div>
          <p>
            Uz spomenute župnike u župi je tijekom 75 godina djelovalo i 40
            kapelana/ suradnika, koji su se izrazitije posvetili radu s mladima.
          </p>
          <p>
            Uz župu ovdje su djelovale i slijedeće odgojno-upravne zajednice:
          </p>
          <ul>
            <li>
              <span>&rarr;</span>
              <div>zajednica za formaciju braće pomoćnika</div>
            </li>
            <li>
              <span>&rarr;</span>
              <div>salezijanski bogoslovski studentat</div>
            </li>
            <li>
              <span>&rarr;</span>
              <div>salezijanski novicijat</div>
            </li>
            <li>
              <span>&rarr;</span>
              <div>Hrvatski salezijanski provincijalat</div>
            </li>
            <li>
              <span>&rarr;</span>
              <div>
                od 2001. godine ovdje djeluje i Zajednica za odgoj salezijanskih
                kandidata
              </div>
            </li>
            <li>
              <span>&rarr;</span>
              <div>
                u župi već nekoliko desetljeća djeluju i sestre Klanjateljice
                Krvi Kristove
              </div>
            </li>
          </ul>
          <p>
            Djelo sv. Ivana Bosca za odgoj mladeži rašireno po cijelom svijetu
            našlo je i u Podsusedu svoje mjesto. Započelo je s grupom od 15
            dječaka, ali II svjetski rat i poratne prilike zakočile su razvoj.
            Trebalo je početi snalaziti se u novim uvjetima.
          </p>
          <p>
            Djelo se nastavilo na evangelizacijskom, odgojnom, katehetskom,
            sportskom, glazbenom, karitativnom i izdavačkom planu. Novo vrijeme
            postavlja pred nas nove izazove.Don Boscova karizma i zaštita
            pokazuju nam put.
          </p>
          <p>
            Crkva sv. Ivana Bosca i župa u Podsusedu bila je i ostaje duhovno
            središte kako za svećenike koji su tu djelovali i djeluju tako i za
            župljane velikodušne suradnike i podupiratelje.
          </p>
          <div id="animatori"></div>
          <p>
            Hvala svima koji svojom suradnjom, pažnjom i pomoći podupiru i
            stvaraju prisno obiteljsko ozračje koje omogućuje kreativno i
            radosno župno zajedništvo za perspektivniji hod u budućnosti
          </p>
        </div>
      </div>
    </section>
  );
}
