export default function SvetaMisa() {
  return (
    <section class="section">
      <h1>Sveta Misa</h1>
      <div class="misa">
        <h5>Radnim danom:</h5>
        <p>
          07:00 <span>*</span>, 18:30
        </p>
      </div>
      <div class="misa">
        <h5>Nedjeljom i blagdanom:</h5>
        <p>
          07:00, 09:00, 10:30, 12:00<span>**</span>, 18:30
        </p>
      </div>
      <p>
        <span>*</span> U prosincu u 06:00 zornice
      </p>
      <p>
        <span>**</span> Mise nedjeljom u 12 sati nema u ljetnim mjesecima
      </p>
    </section>
  );
}
