import { useEffect } from "react";

// Images
import PastoralniCentar from "../../Images/pastoralni centar/centar5.jpg";

export default function Kontakt() {
  useEffect(() => {
    document.title = "Don Bosco | Kontakt";
  }, []);
  return (
    <>
      <main>
        <div className="main-img">
          <img src={PastoralniCentar} alt="Župa Don Bosco" />
          <div className="main-dusa">
            <h2>"Loše navike mogu se nadvladati jakom voljom!"</h2>
            <p>
              <i className="fi fi-tr-horizontal-rule"></i> Don Bosco
            </p>
          </div>
        </div>
      </main>

      <section className="section">
        <h2>Župa Don Bosco</h2>
        <div>
          <div className="contact-link ">
            <h3>Župnik</h3>
            <h4>Don Tunjo Blažević</h4>
          </div>
          <div className="contact-link">
            <i className="fi fi-rr-phone-call"></i>
            <a href="tel:098 92 91 607">
              <span>Tel:</span> 098 92 91 607
            </a>
          </div>

          <div className="light-border"></div>

          <div className="contact-link">
            <i className="fi fi-rr-envelope"></i>
            <a href="mailto:zupa.podsused@zg-nadbiskupija.hr">
              <span>Email:</span> zupa.podsused@zg-nadbiskupija.hr
            </a>
          </div>
          <div className="contact-link">
            <i className="fi fi-rr-phone-call"></i>
            <a href="tel:(01) 3492 033">
              <span>Župni ured:</span> (01) 3492 033
            </a>
          </div>
        </div>
        <DonBoscoLocation />
        <div className="light-border"></div>
        <h2>Raspored</h2>

        <div className="raspored-misa ">
          <h3>SVETIH MISA</h3>
          <div>
            <h5>
              Radnim danom <span>(Pon-Pet):</span>{" "}
            </h5>
            <p>
              07:00 <span>*</span>, 18:30{" "}
            </p>
          </div>
          <div>
            <h5>Subotom:</h5>
            <p>
              07:00 <span>*</span>, 18:30{" "}
            </p>
          </div>
          <div>
            <h5>Nedjeljom i blagdanom:</h5>
            <p>
              07:00, 09:00, 10:30, 12:00 <span>**</span>, 18:30
            </p>
          </div>

          <div>
            <p>
              <span>*</span> U prosincu u 06:00 zornice
            </p>
            <p>
              <span>**</span> Mise nedjeljom u 12 sati nema u ljetnim mjesecima
            </p>
          </div>
        </div>

        <div className="light-border"></div>

        <div className="raspored-misa">
          <h3>ISPOVIJEDI</h3>
          <div>
            <h5>Utorak</h5>
            <p>
              07:00 <span>*</span>, 18:30{" "}
            </p>
          </div>
          <div>
            <h5>Četvrtak:</h5>
            <p>
              07:00 <span>*</span>, 18:30{" "}
            </p>
          </div>
        </div>

        <div className="light-border"></div>

        <div className="raspored-misa">
          <h3>ŽUPNI URED</h3>
          <div>
            <h5>Utorak</h5>
            <p>08:00 - 10:00</p>
            <p>16:30 - 17:30</p>
          </div>
          <div>
            <h5>Petak</h5>
            <p>08:00 - 10:00</p>
            <p>16:30 - 17:30</p>
          </div>
        </div>

        <div className="light-border"></div>

        <div className="raspored-misa">
          <h3>KLANJANJE</h3>
          <div>
            <h5>Svaki petak</h5>
            <p>21:00</p>
          </div>
        </div>

        <div className="light-border"></div>

        <div className="raspored-misa">
          <h3>KRUNICA</h3>
          <div>
            <h5>Svaki dan</h5>
            <p>
              21:30 <br></br>
              <span>(na crkvenom igralištu)</span>
            </p>
          </div>
        </div>

        <div className="light-border"></div>

        <div className="raspored-misa">
          <h3>OSTALO</h3>
          <div>
            <h5>Poštanski broj:</h5>
            <p>Poštanski broj: 10 090, Susedgrad</p>
            <h5>OIB župe:</h5>
            <p>79661269278</p>
            <h5>IBAN župe:</h5>
            <p> HR 8923600001101576679</p>
            <p>
              <span>Zagrebačka banka</span>
            </p>
          </div>
        </div>

        <div className="light-border"></div>

        <h2>Kapelica Sv. Martina</h2>
        <div className="contact-link">
          <i className="fi fi-rr-marker"></i>
          <a href="https://goo.gl/maps/j7hQ6C15HDEQu9zf9">
            Ul. Sutinska vrela 3, 10000, Zagreb
          </a>
        </div>
        <SvMartinLocation />
      </section>

      <div className="light-border"></div>
      <UpitKontakt />
    </>
  );
}

function DonBoscoLocation() {
  return (
    <iframe
      title="Don Bosco Location"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2780.6783368519864!2d15.836959576293317!3d45.81770007108198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4765d1dcc4f1b7a5%3A0xf7272552ec5b9a5f!2sCrkva%20sv.%20Ivan%20Bosco!5e0!3m2!1shr!2shr!4v1694106345491!5m2!1shr!2shr"
      width="600"
      height="450"
      style={{ border: "0" }}
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  );
}

function SvMartinLocation() {
  return (
    <iframe
      title="Sv Martin Location"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2780.4493549837794!2d15.830843176293618!3d45.82228527108208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4765d1d902571a35%3A0x23fe71be6dca1e86!2sCrkva%20sv.%20Martin!5e0!3m2!1shr!2shr!4v1694114031390!5m2!1shr!2shr"
      width="600"
      height="450"
      style={{ border: "0" }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
}

function UpitKontakt() {
  /*  <!-- ----------SECTION IMATE PITANJA---------- --> */
  return (
    <section className="section">
      <h1>Imate pitanja?</h1>
      <div>
        <h3>Pošaljite nam obrazac</h3>
      </div>
      <form
        action="https://api.web3forms.com/submit"
        method="POST"
        className="contact-form"
        id="form"
      >
        {/* <!-- Key --> */}
        <input type="hidden" name="access_key" value="" />
        {/*  <!-- Redirect --> */}
        <input
          type="hidden"
          name="redirect"
          value="https://don-bosco.pages.dev/thanks.html"
        />
        {/*  <!-- From name --> */}
        <input type="hidden" name="from_name" value="Informacije" />
        {/*  <!-- Subject name --> */}
        <input type="hidden" name="subject" value="Novi upit" />
        {/*   <!--  --> */}

        <label for="name">
          Ime i prezime<span>*</span>
        </label>
        <input
          type="text"
          name="Ime i Prezime"
          placeholder="Ime i prezime"
          required
        />
        <label for="email">
          Email: <span>*</span>
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="primjer@gmail.com"
          required
        />
        <label for="phone">
          Mobitel: <span>*</span>
        </label>
        <input
          type="tel"
          name="Broj mobitela"
          id="phone"
          placeholder="+385 1 234 5678"
          required
        />

        <label for="message">Pošaljite poruku:</label>
        <textarea
          name="Poruka"
          id="message"
          placeholder="Vaša poruka..."
        ></textarea>

        {/*  <!-- Honeypot --> */}
        <input
          type="checkbox"
          name="botcheck"
          className="hidden"
          style={{ display: "none" }}
        />
        <input type="submit" value="POŠALJI" id="submit-btn" />
      </form>
    </section>
  );
}
