import { useState } from "react";

export default function ObavijestiText() {
  const [show, setShow] = useState([false, false]);

  const toggleShowText = (index) => {
    const newShow = [...show];
    newShow[index] = !newShow[index];
    setShow(newShow);
  };

  return (
    <section className="section">
      <h1>Obavijesti</h1>
      {/* <!-- ČETVRTA NEDJELJA DOŠAŠĆA --> */}
      <div className="obavijesti-box">
        <h3>ČETVRTA NEDJELJA DOŠAŠĆA</h3>
        <h5>24.12. - 31.12.2023.</h5>
        <div className="obavijesti-container" id="slide">
          <div className="testimonial">
            <div className="slide-row">
              <div className="slide-col">
                {/*  <!-- OBAVIJEST 1 --> */}
                <div
                  className={
                    show[0] ? "obavijesti-text active-text" : "obavijesti-text"
                  }
                  onClick={() => toggleShowText(0)}
                >
                  <div className="arrow-box">
                    <div className="arrow"></div>
                    <div className="arrow"></div>
                  </div>
                  <p>
                    Danas je Badnjak, 24. prosinca. Svete mise bit će u 7, 9 i
                    10.30 te Polnoćke u 20 sati – za djecu; u 22 sata – za mlade
                    i Polnoćka za odrasle – u 24 sata.
                  </p>
                </div>
                {/*  <!-- OBAVIJEST 2 --> */}
                <div
                  className={
                    show[1] ? "obavijesti-text active-text" : "obavijesti-text"
                  }
                  onClick={() => toggleShowText(1)}
                >
                  <div className="arrow-box">
                    <div className="arrow"></div>
                    <div className="arrow"></div>
                  </div>
                  <p>
                    Sutra je Svetkovina Rođenja Isusa Krista – Božić. Sv. Mise
                    su kao i nedjeljom u 7, 9, 10.30, 12.00 i navečer u 18.30
                    sati. Sretan i blagoslovljen Božić – vama, vašim obiteljima,
                    odgojiteljima, sestrama Klanjateljicama Krvi Kristove i svim
                    vjernicima naše župne zajednice kao i onima koji su iz
                    drugih župa, žele vaši salezijanci.
                  </p>
                </div>
                {/*  <!-- OBAVIJEST 3 --> */}
                <div
                  className={
                    show[2] ? "obavijesti-text active-text" : "obavijesti-text"
                  }
                  onClick={() => toggleShowText(2)}
                >
                  <div className="arrow-box">
                    <div className="arrow"></div>
                    <div className="arrow"></div>
                  </div>
                  <p>
                    Na blagdan sv. Stjepana prvomučenika svete mise su u 7 i
                    navečer u 18.30 sati. U 9 sati započinjemo blagoslov
                    obitelji prema rasporedu kojega ste, nadamo se, svi dobili.
                    Ako netko ne bude u mogućnosti primiti svećenika, prema
                    rasporedu, neka se javi u župni ured i dogovori naknadni
                    termin za blagoslov.
                  </p>
                </div>
                {/*  <!-- OBAVIJEST 4 --> */}
                <div
                  className={
                    show[3] ? "obavijesti-text active-text" : "obavijesti-text"
                  }
                  onClick={() => toggleShowText(3)}
                >
                  <div className="arrow-box">
                    <div className="arrow"></div>
                    <div className="arrow"></div>
                  </div>
                  <p>
                    Želja nam je informirati vas o izgradnji Pastoralnog centra
                    Sv. Ivan Bosco. Otvorena je prodaja zemljišta u Banskim
                    Vinogradima. Ako je netko zainteresiran, ili ako pozna
                    potencijalnoga kupca, neka se javi u župni ured.
                  </p>
                </div>
                {/* <!-- OBAVIJEST 5 --> */}
                <div
                  className={
                    show[4] ? "obavijesti-text active-text" : "obavijesti-text"
                  }
                  onClick={() => toggleShowText(4)}
                >
                  <div className="arrow-box">
                    <div className="arrow"></div>
                    <div className="arrow"></div>
                  </div>
                  <p>
                    U srijedu, 27. prosinca, blagdan je sv. Ivana apostola i
                    evanđeliste: svete mise su u 7 i večernja u 18.30. sati.
                  </p>
                </div>
                {/*   <!-- OBAVIJEST 6 --> */}
                <div
                  className={
                    show[5] ? "obavijesti-text active-text" : "obavijesti-text"
                  }
                  onClick={() => toggleShowText(5)}
                >
                  <div className="arrow-box">
                    <div className="arrow"></div>
                    <div className="arrow"></div>
                  </div>
                  <p>
                    U četvrtak, 28. prosinca, blagdan je Nevine Dječice,
                    mučenika. Svete mise su kao radnim danom.
                  </p>
                </div>
                {/*  <!-- OBAVIJEST 7 --> */}
                <div
                  className={
                    show[6] ? "obavijesti-text active-text" : "obavijesti-text"
                  }
                  onClick={() => toggleShowText(6)}
                >
                  <div className="arrow-box">
                    <div className="arrow"></div>
                    <div className="arrow"></div>
                  </div>
                  <p>
                    U Nedjelju, 31. prosinca, Sveta je obitelj Isusa, Marije i
                    Josipa, Stara godina – Zahvalnica. Sv. mise su u 7, 9,
                    10.30, 12.00 i 18.30 sati. Na svečanoj misi zahvalnici
                    pročitat ćemo statistiku naše župe.
                  </p>
                </div>
                {/*  <!-- OBAVIJEST 8 --> */}
                <div
                  className={
                    show[7] ? "obavijesti-text active-text" : "obavijesti-text"
                  }
                  onClick={() => toggleShowText(7)}
                >
                  <div className="arrow-box">
                    <div className="arrow"></div>
                    <div className="arrow"></div>
                  </div>
                  <p>
                    Zahvaljujemo vrijednim župljanima koji su sudjelovali u
                    kićenju bora, slaganju jaslica i čišćenju crkve i okoliša.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
