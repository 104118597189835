import { HashLink } from "react-router-hash-link";

export default function PastoralniVideo() {
  return (
    <section id="section-pastoralni" className="section">
      <h1>Pastoralni centar</h1>
      <div className="pastoralni-video">
        <iframe
          src="https://www.youtube.com/embed/TFdxcKIkrjI?si=NUJA_aP80g1U0Zny"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          id="video"
        ></iframe>
        <div>
          <p className="pastoralni-text">
            Draga braćo i sestre! Radosna vas srca obavještavamo da je u
            Podsusedu pokrenut projekt izgradnje novog pastoralnog centra "Sv.
            Ivan Bosco".
          </p>
        </div>
        <HashLink smooth to="pastoralniCentar/#pastoralni-centar">
          Više Informacija &rarr;
        </HashLink>
      </div>
    </section>
  );
}
