import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// React Router
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Donacije,
  Footer,
  Kontakt,
  Navigation,
  PastoralniCentar,
  Obavijesti,
  Zupa,
  Salezijanci,
  Thanks,
  Animatori,
  Caritas,
  Citaci,
  Ministranti,
  Pjevaci,
  PrijaviSe,
} from "./Components";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Navigation />
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/obavijesti" element={<Obavijesti />} />
      <Route path="/zupa" element={<Zupa />} />
      <Route path="/PastoralniCentar" element={<PastoralniCentar />} />
      <Route path="/donacije" element={<Donacije />} />
      <Route path="/salezijanci" element={<Salezijanci />} />
      <Route path="/kontakt" element={<Kontakt />} />
      <Route path="/hvala" element={<Thanks />} />
      {/* UKLJUCI SE U ŽUPU */}
      <Route path="/animatori" element={<Animatori />} />
      <Route path="/caritas" element={<Caritas />} />
      <Route path="/čitači" element={<Citaci />} />
      <Route path="/ministranti" element={<Ministranti />} />
      <Route path="/pjevači" element={<Pjevaci />} />
      <Route path="/prijava" element={<PrijaviSe />} />
    </Routes>
    <Footer />
  </BrowserRouter>
);
