import Logo from "../../Images/logo/DBKucaB.png";
import PastoralniCentar3 from "../..//Images/pastoralni centar/centar3.jpg";
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Thanks() {
  const [showNavigation, setShowNavigation] = useState(false);
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    document.title = "Don Bosco | Hvala";
    setShowNavigation(true);
    setShowFooter(false);
  }, []);

  return (
    <>
      {showNavigation && <Navigation />}
      <div className="thanks">
        <img
          className="thanks-img"
          src={PastoralniCentar3}
          alt="Pastoralni centar"
        />
        <div className="thanks-box">
          <img src={Logo} alt="Don Bosco logo" />
          <h2>Vaš obrazac je poslan!</h2>
          <p>Kontaktirati ćemo Vas u najkraćem mogućem roku!</p>
          <div>
            <Link to="/" className="btn">
              Povratak na stranicu! &rarr;
            </Link>
          </div>
        </div>
      </div>
      {showFooter && <Footer />}
    </>
  );
}
