import Logo from "../../Images/logo/DBKucaB.png";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function Navigation() {
  const [show, setShow] = useState(false);
  const [navbarStyle, setNavbarStyle] = useState({
    backgroundColor: "transparent",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const hideNavigation = ["/hvala"];

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY / window.innerHeight;
      if (scrolled >= 0.6) {
        setNavbarStyle({
          backgroundColor: "#3c9a78",
        });
      } else {
        setNavbarStyle({
          backgroundColor: "transparent",
        });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Close Nav-list when new page is open
  const closeMenu = () => {
    setShow(false);
  };

  // Scroll to top of the page
  const handleClick = (path) => {
    closeMenu();
    navigate(path);
    window.scrollTo(0, 0);
  };

  if (hideNavigation.includes(location.pathname)) {
    return <></>;
  }

  return (
    <nav id="navbar" style={navbarStyle}>
      <ul className={show ? "nav-links active" : "nav-links"}>
        <li>
          <Link
            className="navbar-links"
            to="/"
            onClick={() => handleClick("/")}
          >
            Naslovnica
          </Link>
        </li>
        <div className="link-border"></div>
        <li>
          <Link
            className="navbar-links"
            to="obavijesti"
            onClick={() => handleClick("/")}
          >
            Župne obavijesti
          </Link>
        </li>
        <div className="link-border"></div>
        <li>
          <Link
            className="navbar-links"
            to="zupa"
            onClick={() => handleClick("/")}
          >
            Župa <i className="fi fi-rr-caret-down"></i>
          </Link>

          <ul className="submenu">
            <li>
              <HashLink
                className="navbar-links"
                smooth
                to="zupa/#povijest"
                onClick={() => handleClick("/")}
              >
                &rarr; povijest
              </HashLink>
            </li>
            <li>
              <HashLink
                className="navbar-links"
                smooth
                to="zupa/#dogadaji"
                onClick={() => handleClick("/")}
              >
                &rarr; događaji
              </HashLink>
            </li>
            <li>
              <HashLink
                className="navbar-links"
                smooth
                to="zupa/#svecenici"
                onClick={() => handleClick("/")}
              >
                &rarr; svećenici
              </HashLink>
            </li>
          </ul>
        </li>
        <div className="link-border"></div>
        <li>
          <Link
            className="navbar-links"
            to="pastoralniCentar"
            onClick={() => handleClick("/")}
          >
            Pastoralni centar
          </Link>
        </li>
        <div className="link-border"></div>
        <li>
          <Link
            className="navbar-links"
            to="salezijanci"
            onClick={() => handleClick("/")}
          >
            Salezijanci
          </Link>
        </li>
        <div className="link-border"></div>

        <li>
          <Link
            className="navbar-links"
            to="kontakt"
            onClick={() => handleClick("/")}
          >
            Kontakt
          </Link>
        </li>

        <div className="social-links">
          <Link
            to="https://www.instagram.com/oratorij_adc/?hl=hr"
            target="_blank"
          >
            <i class="fi fi-brands-instagram"></i>
          </Link>

          <Link to="https://www.facebook.com/oratorijpodsused/" target="_blank">
            <i class="fi fi-brands-facebook"></i>
          </Link>
          <Link
            to="https://www.youtube.com/@oratorijalexandrinadacosta4747"
            target="_blank"
          >
            <i class="fi fi-brands-youtube"></i>
          </Link>
        </div>
      </ul>

      <div
        className={show ? "hamburger active" : "hamburger"}
        onClick={() => setShow(!show)}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      <Link to="/">
        <h1 id="navbar-title">Don Bosco</h1>
      </Link>

      <img src={Logo} alt="Don Bosco Logo" id="nav-logo" />
    </nav>
  );
}
