import { useEffect } from "react";

// Images
import SalezijanciImg from "../../Images/salezijanci/salezijanci.jpg";

export default function Salezijanci() {
  useEffect(() => {
    document.title = "Don Bosco | Salezijanci";
  }, []);
  return (
    <>
      <main>
        <div className="main-img">
          <img src={SalezijanciImg} alt="Salezijanci Župe Don Bosco" />
          <div className="main-dusa">
            <h2>"Daj mi duše, drugo uzmi"</h2>
            <p>
              <i className="fi fi-tr-horizontal-rule"></i> Don Bosco
            </p>
          </div>
        </div>
      </main>
      <DonBosco />
      <SalezijanciSection />
    </>
  );
}

/* *****DON BOSCO***** */
function DonBosco() {
  return (
    <>
      <section className="section" id="DonBosco">
        <h1>Don Bosco</h1>
        <div className="salezijanci-lenta">
          <div className="salezijanci-box">
            <h5>
              1815 -<span> 16. kolovoza</span>
            </h5>
            <p>
              Rodio se u selu Becchi kod Castelnuovo d'Asti (danas Castelnuovo
              don Bosco, sjeverna Italija) . Roditelji su mu bili siromašni
              zemljoradnici. Kad je imao dvije godine, umro mu je otac. Njegova
              mati Margarita odgojila ga je uz velike napore, ali s ljubavlju.
              Učeći kako se obrađuje zemlja, Ivan je od nje naučio promatrati
              Božju velicinu u zvjezdanom nebu, u bogatstvu plodova i u tuči
              koja je znala uništiti vinograde.
            </p>
          </div>
          <div className="salezijanci-box">
            <h5>
              1841 -<span> 26 godina</span>
            </h5>
            <p>
              Imao je 26 godina kad je 1841., kao istom zaređeni svećenik, došao
              u Torino. Njegov duhovni vođa svećenik Josip Cafasso, dao mu je
              ovaj savjet: "Pođi gradom i dobro promatraj život!" Tada je vidio
              svu ljudsku bijedu. U predgrađima je sve kuhalo od nezadovoljstva.
              Život u barakama radničkih četvrti bio je očajan. Mladi su lutali
              ulicama besposleni, izgubljeni i spremni na svako zlo.
            </p>
          </div>
          <div className="salezijanci-box">
            <h5>
              1841 -<span> 8. prosinca</span>
            </h5>
            <p>
              Na blagdan Bezgrešne, 8. prosinca 1841., približio se mladom
              doseljeniku, dječaku Bartolomeu Garelliju iz Astija. Tri dana
              kasnije imao je devet takvih sirotana a poslije tri mjeseca broj
              se povećao na dvadeset i pet. U ljeto 1842. bilo ih je osamdeset.
              Tako nastaje oratorij.
            </p>
          </div>
          <div className="salezijanci-box">
            <h5>
              1854 -<span> 20. siječnja</span>
            </h5>
            <p>
              Službeno je potvrđena Salezijanska družba. 30. srpnja 1860. prvi
              "don Boscov dobrovoljac" Mihael Rua postao je svećenik. Na kraju
              svog života don Bosco će moći reći da je gotovo 3000 svećenika
              izašlo iz redova njegovih dječaka.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

/* *****SALEZIJANCI***** */
function SalezijanciSection() {
  return (
    <>
      <section className="section" id="salezijanci">
        <h1>Salezijanci</h1>
        <div>
          <h3>In progress...</h3>
        </div>
      </section>
    </>
  );
}
